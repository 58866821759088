import { Box, Button, Card, CardContent, CardMedia, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Toolbar, Typography } from "@mui/material"

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const courses = [
    {
        link: 'chandradhaara',
        title: 'Chandradhaara',
        subtitle: 'Clarity, confidence, and cleansing',
        description: 'Begin your journey of healing and transformation through Chandradhaara, a program that leverages the moon’s energy to guide you. Through this program, you can erase and demolish any blockages from your life that may hinder your present. With spiritual healing, chakra-based healing and the moon’s energy, you will be able to absorb positivity and enlightenment within you.',
        thumbnail: 'chandradhaara.jpg',
        features: [
            {
                title: 'Benefits of Learning this Course',
                points: [
                    'Uncover the secrets of your past with Past Life Regression Therapy',
                    'Heal and cleanse yourself from negative energies',
                    'Inner child healing for improving your relationship with yourself',
                    'Moon healing for energy rejuvenation',
                    'Chakra-based healing is used to induce balance within your life.'
                ]
            },
            {
                title: 'What Will You Gain From this Course?',
                points: [
                    'A healthier and more insightful relationship with yourself',
                    'Rid yourself of negative energies for a refreshed and balanced life.',
                    'Gain clarity and confidence with yourself through past life healing',
                    'Discover an improved and enlightened version of yourself',
                    'Deepen your bond with the earth and its natural elements',
                ]
            }
        ]
    },
    {
        link: 'samrudhi',
        title: 'Samrudhi',
        subtitle: 'Serenity, security, and stability',
        description: 'Clear away your financial struggles and lead an abundant life with the help of Samrudhi. It’s a unique program that allows you to resent your mindset about money and its energy. Like positivity and negativity, money is energy meant to be welcomed within your household. Achieve your financial goals and erase any negative associations with money with the help of this unique program.',
        thumbnail: 'samrudhi.jpg',
        features: [
            {
                title: 'Benefits of Learning this Course',
                points: [
                    'Reduction of Debts and Financial Struggles',
                    'Transform your mindset about money',
                    'Walk towards financial independence and freedom',
                    'Learn how to utilise the energy of money wisely',
                    'Gain confidence to make intelligent financial decisions',
                ]
            },
            {
                title: 'What will you gain from this Course?',
                points: [
                    'Erase any negative mindset associated with money',
                    'Venture into an abundance-filled lifestyle',
                    'Acquire the skills needed for proper money management',
                    "Gain a financial blueprint to understand money's role in your life",
                    'Remove blocks that may hinder you from your economic goals',

                ]
            }
        ]
    },
    {
        link: 'nirvana',
        title: 'Nirvana',
        subtitle: 'Mindfulness, mending, and motivation',
        description: 'Relationships, health, and wealth are the essence of human life. To be more connected with your inner self, to be spiritual, and to be surrounded by an aura of light, the Nirvana program is here to assist you. This program can help you overcome and recalibrate disarray in any negativity and disconnect within your life. Each method utilised in the Nirvana program will allow you to gain the tools to lead a more harmonious and peaceful life.',
        thumbnail: 'nirvana.jpg',
        features: [
            {
                title: 'Benefits of This Course',
                points: [
                    'Induce harmony and peace within your life',
                    'Remove any blockades that may be hindering your goals',
                    'Improve your relationship with yourself and others around you.',
                    'Become a healthier and happier version of yourself',
                    'Bring about abundance and wealth through a mindset change.'
                ]
            },
            {
                title: 'What will you gain?',
                points: [
                    'An improved mindset with the help of NLP(Neuro - Linguistic Programming)',
                    'Restore balance within your mental, physical and spiritual well - being through Chakra healing.',
                    'Heal inner traumas through hypnosis.',
                    'Gain mindfulness and enlightenment through meditation.',
                    'Tools and insights needed to lead a spiritual and nurturing life.'
                ]
            }
        ]
    },
    {
        link: 'public_speaking',
        title: 'Public Speaking',
        subtitle: 'Confidence, communication, clarity',
        description: "Speaking eloquently is essential to succeed in school, in college, and even professionally.Rid yourself of stage fright, anxiety or underconfidence with Saartha's public speaking course.Any issues that might deter you from communicating most articulately shall be promptly healed from this activity and task - based course.With each activity you undertake, you become one step closer to speaking expressively with utmost confidence.With this course, your inhibitions holding you back will slowly slip away to introduce a better version of yourself.",
        thumbnail: 'public_speaking.jpg',
        features: [
            {
                title: 'What Changes Will This Course Bring You ?',
                points: [
                    'Improved Communication skills',
                    'Overcome fear of public speaking',
                    'Self - Confidence and Articulation',
                    'Better Listening Capabilities',
                    'Personal development'
                ]
            },
            {
                title: 'What Will You Learn ?',
                points: [
                    'How do you prepare a Speech and organise it?',
                    'Body Language, Voice Modulation and Eye Contact',
                    'Managing Stage Fright with Relaxation and Visualization Techniques',
                    'Maintaining respectful communications with honesty and integrity',
                    'Speaking with Clarity, Coherence and Confidence'
                ]
            }
        ]
    },
    {
        link: 'personal_mentorship',
        title: 'Personal Mentorship',
        subtitle: 'Growth, guidance, gratitude',
        description: "Spreading positivity and light through words and actions can be a hobby for some and someone's calling.If you believe spreading light and touching the lives of those around you is your calling in life, then you can enrol in the Personal Mentorship program.Through this undertaking, you can learn the secrets of the Himalayas and the art of yogic healing.The Personal Mentorship program is a one - year customised program that will allow you to gain the tools needed to nourish and improve the well - being of others.Begin your journey into spiritual healing and transform the lives of those around you. ",
        thumbnail: 'personal_mentorship.jpg',
        features: [
            {
                title: 'What Changes will this Course Bring you?',
                points: [
                    'Give you the tools needed to empower the lives of people',
                    'Teach you how to heal individuals holistically.',
                    'Uplift you to lead and guide others towards a better path.',
                    'Lead a more purposeful life fulfilling the mission',
                    'Gain clarity and insights on how to uplift others and yourself. '
                ]
            },
            {
                title: 'What Will You Learn?',
                points: [
                    'Spiritual Healing',
                    'Chakra healing',
                    'Meditations',
                    'Yogic Healing',
                    'Neuro - linguistic Programming'
                ]
            }
        ]
    },
    {
        link: 'superstar_employee_mentorship',
        title: 'Superstar Employee Mentorship',
        subtitle: 'Productivity, prosperity, andpositivity',
        description: "Suppose you're looking to boost the output of your employees or improve yourself to become the best employee for your organisation.In that case, the Superstar Employee Mentorship program is for you! Productivity is the most critical aspect of working toward any goal, and each member of an organisation must be productive.If achieving goals and quickly climbing the corporate ladder is part of your plans, then the Superstar Employee Mentorship program can help you. ",
        thumbnail: 'superstar_employee_mentorship.jpg',
        features: [
            {
                title: 'What Changes Can this Course Bring?',
                points: [
                    'Increased productivity',
                    'Better performance',
                    'Self - confidence boost',
                    'Future Leaders',
                    'Unique Company Culture'
                ]
            },
            {
                title: 'What will you Learn?',
                points: [
                    'Become better personally, professionally and mentally.',
                    'How to manage your time effectively.',
                    'The art of problem - solving and developing solutions',
                    'Set realistic and achievable goals and create plans to achieve them.',
                    'Increase awareness of your strengths and areas for improvement.'
                ]
            }
        ]
    }

]



const Course = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedCourseName, setSelectedCourseName] = useState('');

    useEffect(() => {
        if (searchParams.get('name') != '') {
            setSelectedCourseName(searchParams.get('name'))
        }
    }, [searchParams])

    return (
        <Box>
            <Toolbar sx={{ height: 87.5 }} />
            {selectedCourseName != '' ?
                courses.map((course, k) => (
                    (course.link === selectedCourseName) &&
                    <Box key={k}>

                        <Box
                            sx={{
                                background: `url(/images/courses/${course.link}_banner.jpg)`,
                                backgroundSize: 'cover',
                                backgroundPositionY: 'center'
                            }}
                        >
                            <Box sx={{
                                width: '100%', height: '100%', background: 'rgba(0,0,0,0.3)'
                            }}
                            >
                                <Container>
                                    <Stack direction='column' justifyContent='center' sx={{ height: 350 }}>
                                        <Typography variant='h2' sx={{ color: '#fff' }}>
                                            {course.title}
                                        </Typography>
                                    </Stack>
                                </Container>
                            </Box>
                        </Box>

                        <Container maxWidth='lg' sx={{ my: 10 }}>

                            <Grid container spacing={5}>
                                <Grid item xs={12} md={8}>
                                    <Box>
                                        <span
                                            style={{
                                                backgroundColor: '#FF4501',
                                                color: '#fff',
                                                padding: '3px 16px',
                                                borderRadius: 5,
                                                width: 'fit-content',
                                                fontSize: '15px',
                                                fontFamily: '"Raleway", sans-serif',
                                                fontWeight: 700,
                                                marginBottom: 25
                                            }}
                                        >
                                            COURSE
                                        </span>

                                        <Typography variant='h3' sx={{ mb: 3 }}>
                                            {course.title}, {course.subtitle}
                                        </Typography>

                                        <Box sx={{ height: '100%', alignItems: 'flex-end', display: 'flex' }}>
                                            {<img src={`/images/courses/${course.link}_main.jpg`} width='100%' />}
                                        </Box>

                                        <Typography variant='body1' sx={{ mt: 3, textAlign: 'justify' }}>
                                            {course.description}
                                        </Typography>

                                        {course.features.map((c, i) => (
                                            <Box key={i}>
                                                <Typography variant='h4' sx={{ mt: 5, mb: 3 }}>
                                                    {c.title}
                                                </Typography>

                                                {c.points.map((p, j) => (



                                                    <List dense={true} key={j}>
                                                        <ListItem>
                                                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                                                <Box
                                                                    sx={{
                                                                        width: 24, height: 24,
                                                                        borderRadius: 24, border: '8px solid #fff',
                                                                        position: 'absolute', top: '50%', left: 0,
                                                                        transform: 'translate(0, -50%)',
                                                                        backgroundColor: '#FF4501',
                                                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                                                    }}
                                                                >

                                                                </Box>

                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                                                    {p}
                                                                </Typography>}
                                                            />
                                                        </ListItem>
                                                    </List>
                                                ))}
                                            </Box>
                                        ))}


                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ background: '#fff', px: 2, py: 5, mb: 3, boxShadow: '0 10px 60px rgba(191, 211, 255, .38)' }}>
                                        <Stack direction='column' justifyContent='space-between' spacing={3}>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Stack direction='row' spacing={1} alignItems='center'>
                                                    <OndemandVideoIcon />
                                                    <Typography variant='caption'>
                                                        Videos
                                                    </Typography>
                                                </Stack>
                                                <Typography variant='caption' color='#262d3d'>
                                                    10
                                                </Typography>
                                            </Stack>

                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Stack direction='row' spacing={1} alignItems='center'>
                                                    <Person3OutlinedIcon />
                                                    <Typography variant='caption'>
                                                        Slots
                                                    </Typography>
                                                </Stack>
                                                <Typography variant='caption' color='#262d3d'>
                                                    10
                                                </Typography>
                                            </Stack>

                                        </Stack>
                                        <Button fullWidth variant='contained' size='large' color='secondary' sx={{ mt: 5 }}>
                                            Enroll Now
                                        </Button>
                                    </Box>
                                    <Box sx={{ background: '#fff', p: 2, boxShadow: '0 10px 60px rgba(191, 211, 255, .38)' }}>


                                        <Typography variant='h4' sx={{ mb: 3 }}>
                                            Popular Courses
                                        </Typography>

                                        {courses.map((cc, l) => (

                                            <Card
                                                sx={{ display: 'flex', mb: 3, textDecoration: 'none' }}
                                                key={l}
                                                component={Link}
                                                to={`/course?name=${cc.link}`}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 125 }}
                                                    image={`/images/courses/${cc.thumbnail}`}
                                                    alt={cc.title}
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                                        <Typography component="div" variant="h6">
                                                            {cc.title}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 16, color: '#415674', fontWeight: '400' }} component="div">
                                                            {cc.subtitle}
                                                        </Typography>
                                                    </CardContent>

                                                </Box>

                                            </Card>
                                        ))}

                                    </Box>
                                </Grid>

                            </Grid>


                        </Container>
                    </Box>
                ))
                :
                <Typography variant='h2' sx={{ color: '#fff' }}>
                    Invalid Course
                </Typography>

            }
        </Box>

    )
}

export default Course