import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, colors, hexToRgb, useScrollTrigger } from '@mui/material';


import DoneAllIcon from '@mui/icons-material/DoneAll';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import FestivalIcon from '@mui/icons-material/Festival';

import HandshakeIcon from '@mui/icons-material/Handshake';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';


import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';

import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import Header from './Header';
import Footer from './Footer';

import courses from '../Data/courses.json'
import { useNavigate } from 'react-router-dom';

const membershipPoints = [
    "Yearly Wellness Retreats- A rejuvenating retreat that allows you to connect with similar individuals and discover new ideas, thoughts and relaxation tools.",
    "Guided Meditations - Gain access to daily guided meditations to focus on yourself and become more mindful.",
    "Free Sessions—You can attend various online programs and sessions throughout the year to re-learn and rediscover what you have learned.",
    "Referral Discounts - If you’re introducing members of your family and friends to the beautiful community at Madhubaalan Mind Guru Family, they can avail a referral discount through your membership",
    "Consistent Support - Once you become a member, you will always be part of a community that takes care of each other and looks out for each other."
]


function HomePage() {

    const navigate = useNavigate()

    const handleCourseNav = (link) => {
        navigate(`/course?name=${link}`)
    }


    const coursesRef = React.useRef(null);
    const onMovetoCourses = () => {
        if (coursesRef.current) {
            coursesRef?.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const aboutRef = React.useRef(null);
    const onMovetoAbout = () => {
        aboutRef?.current.scrollIntoView({ behavior: 'smooth' })
    }

    const testimonialsRef = React.useRef(null);
    const onMovetoTestimonials = () => {
        testimonialsRef?.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <Box>
            <Header movetoCourses={onMovetoCourses} movetoAbout={onMovetoAbout} movetoTestimonials={onMovetoTestimonials} />

            <Container maxWidth='lg'>
                <Box
                    sx={{
                        minHeight: '100vh',

                        "&:after": {
                            content: '""',
                            position: 'absolute',
                            right: { xs: '25%', md: 0 },
                            top: 0,
                            zIndex: -1,
                            width: { xs: 350, md: 965 },
                            height: { xs: 350, md: 965 },
                            background: '#f7f7f7',
                            lineHeight: '965px',
                            borderRadius: '50%',
                            textAlign: 'center',
                            transform: 'translate(15%, -50%)',
                        },
                        "&:before": {
                            position: 'absolute',
                            content: '""',
                            left: 0, bottom: 0, zIndex: -1,
                            width: '330px', height: '330px',
                            background: '#f7f7f7',
                            lineHeight: '330px', borderRadius: '50%',
                            textAlign: 'center',
                            transform: 'translate(-25%, 50%)',
                        },
                    }}
                >


                    <Grid container spacing={2} sx={{ height: '100%' }}>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    width: '100%', height: '100%',
                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    pt: { xs: 15, md: 0 }, pb: { xs: 5, md: 0 }
                                }}>
                                <Stack direction='column'>
                                    <span
                                        style={{
                                            backgroundColor: 'rgba(255, 152, 0, 0.1)',
                                            color: '#820300',
                                            padding: '3px 16px',
                                            borderRadius: 5,
                                            width: 'fit-content',
                                            fontSize: '15px',
                                            fontFamily: '"Raleway", sans-serif',
                                            fontWeight: 700,
                                            marginBottom: 20
                                        }}
                                    >
                                        LIFE COACH
                                    </span>
                                    <Typography variant='h1' sx={{ mb: 2.5 }}>
                                        Welcome to Madhubaalan Mind Guru Family
                                    </Typography>
                                    <Typography variant='caption' sx={{ mb: 2.5 }}>
                                        Revolutionise the lives of everyone around us, directly or indirectly.
                                    </Typography>
                                    <Button
                                        variant='contained' size='large' color='secondary'
                                        sx={{ width: '250px' }}
                                        onClick={onMovetoAbout}
                                    >
                                        Read More...
                                    </Button>
                                </Stack>
                            </Box>



                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ pt: { xs: 5, md: 20 }, display: 'flex', alignItems: 'baseline' }}>
                                {<img src='/main-banner.png' width='100%' />}
                            </Box>

                        </Grid>
                    </Grid>


                </Box>
                <Box
                    sx={{
                        color: '#262d3d',
                        fontSize: { xs: 75, sm: 100, md: 165 },
                        fontFamily: '"Raleway", sans-serif',
                        fontWeight: 700,
                        position: 'absolute', bottom: 0, right: 0,
                        opacity: '0.04', zIndex: -1,
                        lineHeight: 0.8
                    }}
                >
                    COACH
                </Box>
            </Container >


            <Box sx={{ backgroundColor: '#820300' }}>
                <Container maxWidth='lg' sx={{ py: 10 }}>

                    <Grid container spacing={5}>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md='auto'>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            width: 100, height: 100,
                                            background: 'rgba(255, 152, 0, 0.1)', borderRadius: 5,
                                            justifyContent: 'center', alignItems: 'center', display: 'flex'
                                        }}>
                                        <BookOnlineIcon color='white' sx={{ fontSize: 50 }}></BookOnlineIcon>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Box>
                                        <Typography variant='h4' sx={{ color: 'text.white' }}>
                                            Online Course
                                        </Typography>
                                        <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                            Madhubaalan Mind Guru is now providing courses online.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md='auto'>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            width: 100, height: 100,
                                            background: 'rgba(255, 152, 0, 0.1)', borderRadius: 5,
                                            justifyContent: 'center', alignItems: 'center', display: 'flex'
                                        }}>
                                        <MissedVideoCallIcon color='white' sx={{ fontSize: 50 }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Box>
                                        <Typography variant='h4' sx={{ color: 'text.white' }}>
                                            High Quality Videos
                                        </Typography>
                                        <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                            Our course videos are professionally made with high quality.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md='auto'>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            width: 100, height: 100,
                                            background: 'rgba(255, 152, 0, 0.1)', borderRadius: 5,
                                            justifyContent: 'center', alignItems: 'center', display: 'flex'
                                        }}>
                                        <FestivalIcon color='white' sx={{ fontSize: 50 }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Box>
                                        <Typography variant='h4' sx={{ color: 'text.white' }}>
                                            Event Updates
                                        </Typography>
                                        <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                            Madhubaalan Mind Guru events now updating online
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>

            </Box>

            <Container maxWidth='lg' sx={{ my: 10 }} ref={aboutRef}>

                <Grid container spacing={10}>
                    <Grid item xs={12} md={7}>
                        <Box>
                            <span
                                style={{
                                    backgroundColor: '#FF4501',
                                    color: '#fff',
                                    padding: '3px 16px',
                                    borderRadius: 5,
                                    width: 'fit-content',
                                    fontSize: '15px',
                                    fontFamily: '"Raleway", sans-serif',
                                    fontWeight: 700,
                                    marginBottom: 25
                                }}
                            >
                                ABOUT ME
                            </span>

                            <Typography variant='h2' sx={{ mb: 3 }}>
                                My Story
                            </Typography>
                            <Box sx={{ mb: 3, textAlign: 'justify', mr: 5 }}>
                                <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                                    As a healer, I have impacted over 2000 students through the lessons I gained from my guru. The aura of positivity and light that surrounds me has allowed me to guide those around me towards the path of spirituality and healing. Healing the individuals I encountered allowed me to create a Madhubaalan Mind Guru Family.
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 3, textAlign: 'justify', ml: 5 }}>
                                <Typography variant='body1'>
                                    However, this wasn't always the case. There was a time when I struggled with depression, debt, anxiety and suicidal thoughts. At the moment when I had decided my end was near, I was introduced to a yogic healer who was a disciple of the monks in the Himalayas. His ability to understand my struggles without words struck me with awe and surprise. His guidance and teachings towards spiritual healing and finding inner peace allowed me to slowly climb up from the rock bottom I had found myself on.
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 3, textAlign: 'justify', mr: 5 }}>
                                <Typography variant='body1'>
                                    Within a year, I slowly but surely transformed from a depressed individual to a happy and healed person. However, my guru asked me to do only one thing in return for the happiness and abundance I had received: spread the message of positivity and light. Thus, with enthusiasm and persistence, I began my journey towards awakening mindfulness and fulfilment within the people around me.
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 3, textAlign: 'justify', ml: 5 }}>
                                <Typography variant='body1'>
                                    With each life I changed, I realised that the light not only entered the person I spoke with but also their families and surroundings. The interconnected web of threads that we humans have allowed me to build the community we have today, a group of individuals connected by the desire to grow, be better, and consistently improve ourselves.
                                </Typography>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <span
                            style={{
                                content: '',
                                left: '50%',
                                top: 0,
                                zIndex: -1,
                                width: '540px',
                                height: '540px',
                                background: '#f7f7f7',
                                lineHeight: '540px',
                                borderRadius: '50%',
                                textAlign: 'center',
                                transform: 'translate(-55%, -10%)'
                            }}
                        >
                        </span>

                        <Box sx={{ height: '100%', alignItems: 'flex-end', display: 'flex' }}>
                            {<img src='/man.jpg' width='100%' />}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormatQuoteOutlinedIcon sx={{ fontSize: 100, color: '#FF4501', opacity: 0.3 }} />
                        <Box sx={{ textAlign: 'justify', mx: 10 }}>
                            <Typography sx={{ fontStyle: 'italic', fontSize: 30 }}>
                                I am the Founder of Madhubaalan Mind Guru Family, and our work aims to revolutionise the lives of everyone around us, directly or indirectly. Join me in this journey of transformation and healing!
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='lg' sx={{ my: 10 }}>
                <span
                    style={{
                        backgroundColor: '#FF4501',
                        color: '#fff',
                        padding: '3px 16px',
                        borderRadius: 5,
                        width: 'fit-content',
                        fontSize: '15px',
                        fontFamily: '"Raleway", sans-serif',
                        fontWeight: 700,
                        marginBottom: 25
                    }}
                >
                    MISSION AND VISION
                </span>

                <Typography variant='h2'>
                    revolutionize the lives of everyone
                </Typography>

                <Grid container spacing={3} sx={{ py: 3 }}>

                    <Grid item xs={12} md={6} sx={{ mt: 5 }}>
                        <Box
                            sx={{
                                // "&:hover": {
                                //     background: "#204ecf",
                                //     '& > h4': {
                                //         color: '##fff',
                                //     },
                                // },
                                p: 5, height: '100%',
                                boxShadow: '10px 0 33px rgba(30, 40, 69, 0.07)',

                            }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md='auto'>
                                    <Box>
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid transparent',
                                                width: '50px',
                                                height: '50px',
                                                background: '#FF9800',
                                                borderRadius: '50%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <TrackChangesIcon color='white' />
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Typography variant='h4' sx={{ mb: 2 }}>
                                        Our Aim
                                    </Typography>
                                    <Typography variant='body1' textAlign='justify'>
                                        It is easy to lose hope and lose one’s way in the world we live in today. However, at Madhubaalan Mind Guru Family, we aim to spread spirituality and love across the globe. Our ardent desire is to help you discover yourself, understand who you are, and redefine the scope of your capabilities. What has seemed impossible before becomes possible and achievable when you believe in yourself and have spirituality guiding you. The lightness from erasing your baggage and blockages will also strengthen your resolve to achieve your dreams.
                                    </Typography>

                                </Grid>
                            </Grid>

                        </Box>

                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mt: 5 }}>
                        <Box
                            sx={{
                                p: 5, height: '100%',
                                boxShadow: '10px 0 33px rgba(30, 40, 69, 0.07)'
                            }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md='auto'>
                                    <Box>
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid transparent',
                                                width: '50px',
                                                height: '50px',
                                                background: '#FF9800',
                                                borderRadius: '50%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <HandshakeIcon color='white' />
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Typography variant='h4' sx={{ mb: 2 }}>
                                        Our Purpose
                                    </Typography>
                                    <Typography variant='body1' textAlign='justify'>
                                        Only love and light can allow us to survive; we aim to spread it everywhere possible. We strive to help people induce abundance and prosperity in their lives with the power of positivity and healing. Spreading love, light, and bliss and enhancing human well-being in all aspects of life is what we wish to achieve. Happiness, peace, and success are all meant to be felt together, and we can help you with each. Having set up our base in Kerala, we aim to reach across India and spread positivity and light as far as we can. Following the secret yogic healings, we strive to heal and improve the lives of each individual we interact with.
                                    </Typography>

                                </Grid>
                            </Grid>

                        </Box>

                    </Grid>

                </Grid>
            </Container>


            <Box sx={{ backgroundColor: '#820300', py: { xs: 0, md: 8 }, my: 5 }}>
                <Container maxWidth='lg'>
                    <Grid container>
                        <Grid item xs={12} md sx={{ pt: { xs: 5, md: 0 } }}>
                            <Typography variant='h2' color='text.white'>
                                10+
                            </Typography>
                            <Typography variant='body2' color='text.white' sx={{ opacity: 0.6 }}>
                                Years Experience
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md sx={{ display: 'flex' }}>
                            <Divider orientation="vertical" flexItem sx={{ height: '100%', background: '#fff' }} />
                        </Grid>
                        <Grid item xs={12} md sx={{ pt: { xs: 5, md: 0 } }}>
                            <Typography variant='h2' color='text.white'>
                                500+
                            </Typography>
                            <Typography variant='body2' color='text.white' sx={{ opacity: 0.6 }}>
                                Sessions
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md sx={{ display: 'flex' }}>
                            <Divider orientation="vertical" flexItem sx={{ height: '100%', background: '#fff' }} />
                        </Grid>
                        <Grid item xs={12} md sx={{ pt: { xs: 5, md: 0 } }}>
                            <Typography variant='h2' color='text.white'>
                                100+
                            </Typography>
                            <Typography variant='body2' color='text.white' sx={{ opacity: 0.6 }}>
                                Videos
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md sx={{ display: 'flex' }} >
                            <Divider orientation="vertical" flexItem sx={{ height: '100%', background: '#fff' }} />
                        </Grid>
                        <Grid item xs={12} md sx={{ py: { xs: 5, md: 0 } }}>
                            <Typography variant='h2' color='text.white'>
                                1000+
                            </Typography>
                            <Typography variant='body2' color='text.white' sx={{ opacity: 0.6 }}>
                                Satisfied Clients
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Container maxWidth='lg' sx={{ py: 5 }} ref={coursesRef}>
                <span
                    style={{
                        backgroundColor: '#FF4501',
                        color: '#fff',
                        padding: '3px 16px',
                        borderRadius: 5,
                        width: 'fit-content',
                        fontSize: '15px',
                        fontFamily: '"Raleway", sans-serif',
                        fontWeight: 700,
                        marginBottom: 25
                    }}
                >
                    SHAPE YOUR LIFE
                </span>

                <Typography variant='h2'>
                    Our Offerings
                </Typography>

                <Grid container spacing={5} sx={{ my: 5 }}>
                    {courses.map((course, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box >
                                <Box sx={{ mb: -10, zIndex: -1, position: 'relative' }}>
                                    {<img src={`/images/courses/${course.thumbnail}`} width='100%' />}
                                </Box>
                                <Box
                                    onClick={() => handleCourseNav(course.link)}
                                    sx={{
                                        mx: 3, p: 3, height: '100%', background: '#fff',
                                        boxShadow: '10px 0 33px rgba(30, 40, 69, 0.07)',
                                        cursor: 'pointer', transition: '.3s',
                                        ":hover": { transform: 'translateY(-25px)' },
                                    }}
                                >

                                    <Typography variant='body1' color='#415674'>
                                        {course.subtitle}
                                    </Typography>
                                    <Typography variant='h4'>
                                        {course.title}
                                    </Typography>
                                    <Typography variant='body2' sx={{ mt: 3 }}>
                                        {course.shortDescription}
                                    </Typography>

                                    <Divider flexItem sx={{ width: '100%', pb: 2, mb: 2 }} />
                                    <Stack direction='row' justifyContent='space-between'>
                                        <Stack direction='row' spacing={1} alignItems='center'>
                                            <OndemandVideoIcon />
                                            <Typography variant='body1' color='#262d3d'>
                                                {course.specs.videos} videos
                                            </Typography>
                                        </Stack>

                                        <Stack direction='row' spacing={1} alignItems='center'>
                                            <Person3OutlinedIcon />
                                            <Typography variant='body1' color='#262d3d'>
                                                {course.specs.slots} Slots
                                            </Typography>
                                        </Stack>

                                    </Stack>
                                </Box>
                            </Box>

                        </Grid>
                    ))}

                </Grid>


            </Container >


            <Container maxWidth='lg' sx={{ py: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5} sx={{ mr: -15 }}>
                        <Box sx={{ py: { xs: 0, md: 10 } }}>
                            {<img src='/video-bg.jpg' width='100%' />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md sx={{ zIndex: -1 }}>
                        <Box
                            sx={{
                                backgroundColor: '#f7f7f7', width: '100%', height: '100%',
                                pl: { xs: 3, md: 20 }, pr: 3, py: 5
                            }}>
                            <span
                                style={{
                                    backgroundColor: '#FF4501',
                                    color: '#fff',
                                    padding: '3px 16px',
                                    borderRadius: 5,
                                    width: 'fit-content',
                                    fontSize: '15px',
                                    fontFamily: '"Raleway", sans-serif',
                                    fontWeight: 700,
                                    marginBottom: 25
                                }}
                            >
                                MEMBERSHIP
                            </span>

                            <Typography variant='h2'>
                                BECOME A MEMBER
                            </Typography>
                            <Box sx={{ mt: 3 }}>
                                <Typography variant='body1'>
                                    Be part of a community, a family of spiritually guided individuals who look out for each other. Becoming a member of Madhubaalan Mind Guru Family will offer you:
                                </Typography>

                                {membershipPoints.map((p, j) => (



                                    <List dense={true} key={j}>
                                        <ListItem>
                                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                                <Box
                                                    sx={{
                                                        width: 24, height: 24,
                                                        borderRadius: 24, border: '8px solid #fff',
                                                        position: 'absolute', top: '50%', left: 0,
                                                        transform: 'translate(0, -50%)',
                                                        backgroundColor: '#FF4501',
                                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                                    }}
                                                >

                                                </Box>

                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                                    {p}
                                                </Typography>}
                                            />
                                        </ListItem>
                                    </List>
                                ))}

                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </Container>

            {/* <Container maxWidth='lg' sx={{ py: 5 }} ref={testimonialsRef}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5} >
                        <span
                            style={{
                                backgroundColor: '#FF4501',
                                color: '#fff',
                                padding: '3px 16px',
                                borderRadius: 5,
                                width: 'fit-content',
                                fontSize: '15px',
                                fontFamily: '"Raleway", sans-serif',
                                fontWeight: 700,
                                marginBottom: 25
                            }}
                        >
                            TESTIMONIALS
                        </span>

                        <Typography variant='h2'>
                            Happy Clients Say About Coach
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                            <Typography variant='body1' sx={{ pr: 2, }}>
                                We are all aligned together at Saartha Positive Family. We are all connected, and each life we touch is connected by a thread to a more extensive web, to our family. At Saartha, we have taught and interacted with over 2000+ students and transformed their lives. Their feedback and positive changes have allowed us to witness the transformation that our work has caused in their lives.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                mb: -18, ml: { xs: 3, md: -12 },
                            }}
                        >
                            {<img src='/author.jpg' />}
                        </Box>
                        <Box
                            sx={{
                                p: 5,
                                boxShadow: '10px 0 33px rgba(30, 40, 69, 0.07)'
                            }}
                        >
                            <Box sx={{
                                p: 3, ml: 12, backgroundColor: '#fff', position: 'relative',
                                zIndex: 99,
                            }}>
                                <Typography variant='h4'> John Doe</Typography>
                                <Typography variant='body1' color='#204ecf'>
                                    Manager
                                </Typography>
                            </Box>
                            <Typography variant='caption'>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremq udantium totam rem aperiam eaque quae abillo inventore veritatis et quasi architecto beatae
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Container> */}

        </Box >


    );
}
export default HomePage;
