import { Box, Container, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material"

const info = [
    'Personal information: name, email address, username, and password',
    'Payment information: credit card details or other payment method information',
    'Course enrollment and progress data',
    'Device and browser information'
]
const useOfInfo = [
    'To provide and improve our online courses and services',
    'To process payments and manage your account',
    'To communicate with you about course updates, promotions, and support',
    'To analyze and improve our website and services'
]

const dataShare = [
    'We do not share your personal information with third-party companies for marketing purposes.',
    'We may share your information with:',
    'Payment processors to process payments',
    'Third-party services to improve our website and services',
    'Law enforcement or government agencies if required by law'
]

const dataSecurity = [
    'We use industry-standard security measures to protect your personal information.',
    'We encrypt sensitive data, such as payment information.'
]

const yourRights = [
    'You have the right to access, update, or delete your personal information.',
    'You have the right to opt - out of marketing communications.'
]

const PrivacyPolicy = () => {
    return (
        <Box>

            <Toolbar />
            <Container sx={{ my: 5 }}>

                <Typography variant='h2' sx={{ m5: 3 }}>
                    Privacy Policy
                </Typography>

                <Typography variant='body1' sx={{ textAlign: 'justify', mb: 3 }}>
                    At madhubaalanmindguru.com, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your personal data when you access or use our website and online courses.
                </Typography>

                <Typography variant='h6'>
                    Information We Collect
                </Typography>

                <List dense={true}>
                    {info.map((e, i) => (


                        <ListItem key={i}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <Box
                                    sx={{
                                        width: 24, height: 24,
                                        borderRadius: 24, border: '8px solid #fff',
                                        position: 'absolute', top: '50%', left: 0,
                                        transform: 'translate(0, -50%)',
                                        backgroundColor: '#FF4501',
                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                    }}
                                >

                                </Box>

                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                    {e}
                                </Typography>}
                            />
                        </ListItem>
                    ))}
                </List>

                <Typography variant='h6'>
                    How We Use Your Information
                </Typography>

                <List dense={true}>
                    {useOfInfo.map((e, i) => (


                        <ListItem key={i}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <Box
                                    sx={{
                                        width: 24, height: 24,
                                        borderRadius: 24, border: '8px solid #fff',
                                        position: 'absolute', top: '50%', left: 0,
                                        transform: 'translate(0, -50%)',
                                        backgroundColor: '#FF4501',
                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                    }}
                                >

                                </Box>

                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                    {e}
                                </Typography>}
                            />
                        </ListItem>
                    ))}
                </List>


                <Typography variant='h6'>
                    Data Sharing
                </Typography>

                <List dense={true}>
                    {dataShare.map((e, i) => (


                        <ListItem key={i}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <Box
                                    sx={{
                                        width: 24, height: 24,
                                        borderRadius: 24, border: '8px solid #fff',
                                        position: 'absolute', top: '50%', left: 0,
                                        transform: 'translate(0, -50%)',
                                        backgroundColor: '#FF4501',
                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                    }}
                                >

                                </Box>

                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                    {e}
                                </Typography>}
                            />
                        </ListItem>
                    ))}
                </List>

                <Typography variant='h6'>
                    Data Security
                </Typography>

                <List dense={true}>
                    {dataSecurity.map((e, i) => (


                        <ListItem key={i}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <Box
                                    sx={{
                                        width: 24, height: 24,
                                        borderRadius: 24, border: '8px solid #fff',
                                        position: 'absolute', top: '50%', left: 0,
                                        transform: 'translate(0, -50%)',
                                        backgroundColor: '#FF4501',
                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                    }}
                                >

                                </Box>

                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                    {e}
                                </Typography>}
                            />
                        </ListItem>
                    ))}
                </List>

                <Typography variant='h6'>
                    Your Rights
                </Typography>

                <List dense={true}>
                    {yourRights.map((e, i) => (


                        <ListItem key={i}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <Box
                                    sx={{
                                        width: 24, height: 24,
                                        borderRadius: 24, border: '8px solid #fff',
                                        position: 'absolute', top: '50%', left: 0,
                                        transform: 'translate(0, -50%)',
                                        backgroundColor: '#FF4501',
                                        boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                    }}
                                >

                                </Box>

                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                    {e}
                                </Typography>}
                            />
                        </ListItem>
                    ))}
                </List>

                <Typography variant='h6'>
                    Cookie Policy
                </Typography>

                <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                    We use cookies to improve your experience and analyze website usage.
                </Typography>

                <Typography variant='h6'>
                    Changes to Refund Policy
                </Typography>

                <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                    We reserve the right to modify or update this privacy policy at any time without prior notice.
                </Typography>
                <Typography variant='h6'>
                    Contact Us
                </Typography>

                <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                    If you have any questions or concerns about our refund policy, please don't hesitate to contact us at madhubaalan80@gmail.com.
                </Typography>
            </Container>
        </Box>


    )
}

export default PrivacyPolicy