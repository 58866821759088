import { Box, Container, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material"

const eligibility = [
    'Requests for refunds must be made within 30 days of purchase.',
    'Refunds will only be issued for online courses.',
]

const refundProcess = [
    'To request a refund, please contact our customer support team at madhubaalan80@gmail.com',
    'Provide your order number, reason for refund, and any other relevant details',
    'Our team will review your request and respond within 5-7 business days',
    'If approved, refunds will be processed within 5-10 business days and credited back to the original payment method.'
]

const refundAmount = [
    'Full refunds will be issued if course is unattended',
    'Partial refunds may be issued for partial completion of courses'
]

const RefundPolicy = () => {
    return (
        <Container sx={{ my: 5 }}>
            <Toolbar />

            <Typography variant='h2' sx={{ my: 3 }}>
                Refund / Cancellation Policy
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify' }}>

                At Madhubaalan Mind Guru, we strive to provide exceptional products and services. However, we understand that sometimes circumstances may arise that require a refund. Our refund policy is designed to be fair and transparent.
            </Typography>

            <Typography variant='h6'>
                Eligibility for Refunds
            </Typography>

            <List dense={true}>
                {eligibility.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6'>
                Refund Process
            </Typography>

            <List dense={true}>
                {refundProcess.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6'>
                Refund Amount
            </Typography>

            <List dense={true}>
                {refundAmount.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6'>
            Changes to Refund Policy
            </Typography>
            
            <Typography variant='body1' sx={{ textAlign: 'justify' }}>
            We reserve the right to modify or update this refund policy at any time without prior notice.
            </Typography>

            <Typography variant='h6'>
            Contact Us
            </Typography>
            
            <Typography variant='body1' sx={{ textAlign: 'justify' }}>
            If you have any questions or concerns about our refund policy, please don't hesitate to contact us at madhubaalan80@gmail.com.
            </Typography>

        </Container>


    )
}

export default RefundPolicy