import * as React from 'react';
import { AppBar, Box, Button, Container, Stack, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import { Link } from 'react-router-dom';


const Header = (props) => {

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    function ElevationScroll(props) {
        const { children } = props;
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
        });


        return React.cloneElement(children, {
            elevation: trigger ? 4 : 0,
            sx: { background: trigger ? "#fff" : "transparent", }
        });
    }
    return (
        <ElevationScroll>
            <AppBar>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>

                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' }
                            }}
                        >
                            {/* {<img src='/logo.png' width='80px' />} */}
                            <Stack direction='column'>
                                <Typography sx={{ fontWeight: 900, fontSize: 32, color: '#000', lineHeight: '32px' }}>
                                    Madhubaalan
                                </Typography>
                                <Typography sx={{ fontWeight: 900, fontSize: 28, lineHeight: '28px' }}>
                                    Mind Guru
                                </Typography>
                            </Stack>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>

                            <Button
                                component={Link}
                                to="/"
                                sx={{ my: 2, px: 3, display: 'block', color: '#262d3d', fontSize: '16px', fontWeight: 600 }}
                            >
                                Home
                            </Button>
                            <Button
                                onClick={props.movetoCourses}
                                sx={{ my: 2, px: 3, display: 'block', color: '#262d3d', fontSize: '16px', fontWeight: 600 }}
                            >
                                Courses
                            </Button>
                            <Button
                                onClick={props.movetoAbout}
                                sx={{ my: 2, px: 3, display: 'block', color: '#262d3d', fontSize: '16px', fontWeight: 600 }}
                            >
                                About
                            </Button>
                            {/* <Button
                                onClick={props.movetoTestimonials}
                                sx={{ my: 2, px: 3, display: 'block', color: '#262d3d', fontSize: '16px', fontWeight: 600 }}
                            >
                                Testimonials
                            </Button> */}

                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, }}>

                            <Typography sx={{ fontWeight: 900, fontSize: 24, color: '#000' }}>
                                Madhubaalan Mind Guru
                            </Typography>
                        </Box>
                        {/* 
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                >
                                    <MenuIcon sx={{ color: '#262d3d', fontSize: 35 }} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}

                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box> */}
                    </Toolbar>
                </Container>
            </AppBar>
        </ElevationScroll>
    )

}

export default Header