import { Box, Container, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material"

const access = [
    'You must be at least 18 years old to enroll in a course.',
    'You are responsible for maintaining the confidentiality of your account information.',
    'You agree to notify us of any unauthorized access or use of your account.'
]

const refund = [
    'You agree to pay the course fees and any applicable taxes.',
    'Refunds are subject to our refund policy (insert refund policy details)'
]

const intellectual = [
    'All course materials are owned by [Your Name/Company] and are protected by copyright.',
    'You may not reproduce, distribute, or modify course materials without our prior written consent.'
]

const conduct = [
    'You agree to use the Website and Services for lawful purposes only.',
    'You may not post or transmit any content that is abusive, harassing, or defamatory.'
]

const TermsAndConditions = () => {
    return (
        <Container sx={{ my: 5 }}>
            <Toolbar />



            <Typography variant='h2' sx={{ mt: 10, mb: 5 }}>
                Terms and Conditions
            </Typography>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Introduction
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 5 }}>
                These Terms and Conditions ("Terms") govern your access to and use of madhubaalanmindguru.com ("Website") and its online courses, materials, and services ("Services")
            </Typography>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Acceptance
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 5 }}>
                By accessing or using the Website or Services, you agree to be bound by these Terms.
            </Typography>



            <Typography variant='h6' sx={{ mb: 3 }}>
                Course Enrollment and Access
            </Typography>

            <List dense={true} sx={{ mb: 5 }}>
                {access.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Payments and Refunds
            </Typography>

            <List dense={true} sx={{ mb: 5 }}>
                {refund.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Intellectual Property
            </Typography>

            <List dense={true} sx={{ mb: 5 }}>
                {intellectual.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6' sx={{ mb: 3 }}>
                User Conduct
            </Typography>

            <List dense={true} sx={{ mb: 5 }}>
                {conduct.map((e, i) => (


                    <ListItem key={i}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <Box
                                sx={{
                                    width: 24, height: 24,
                                    borderRadius: 24, border: '8px solid #fff',
                                    position: 'absolute', top: '50%', left: 0,
                                    transform: 'translate(0, -50%)',
                                    backgroundColor: '#FF4501',
                                    boxShadow: '0 10px 30px rgba(255, 69, 1, .35)'
                                }}
                            >

                            </Box>

                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography sx={{ fontSize: 18, color: '#415674', fontWeight: '400' }}>
                                {e}
                            </Typography>}
                        />
                    </ListItem>
                ))}
            </List>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Disclaimer of Warranties
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 5 }}>
                The Website and Services are provided "as is" without warranties of any kind
            </Typography>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Limitation of Liability
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 5 }}>
                We shall not be liable for any damages arising from your use of the Website or Services
            </Typography>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Governing Law
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 5 }}>
                These Terms shall be governed by and construed in accordance with State of Kerala law
            </Typography>

            <Typography variant='h6' sx={{ mb: 3 }}>
                Changes to Terms
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 5 }}>
                We reserve the right to modify these Terms at any time.
            </Typography>

        </Container>


    )
}

export default TermsAndConditions