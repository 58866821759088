import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material"

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';

import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Box sx={{ backgroundColor: '#820300' }}>
            <Container maxWidth='lg' sx={{ py: 10 }}>

                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h4' sx={{ color: 'text.white', mb: 3 }}>
                            Madhubaalan Mind Guru
                        </Typography>
                        {/* <Typography variant='subtitle1' sx={{ opacity: 0.6, mb: 2 }} color='text.white'>
                            Sit amet consectetur adipisc elit sed do eiusmod temporse incididunt labore dolore
                        </Typography> */}

                        <Stack direction='row' spacing={2}>
                            <Avatar sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                <FacebookOutlinedIcon />
                            </Avatar>
                            <Avatar sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                <SubscriptionsOutlinedIcon />
                            </Avatar>
                        </Stack>

                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Box>
                            <Typography variant='h4' color='text.white' sx={{ mb: 2 }}>
                                Links
                            </Typography>
                            <Box component={Link} to='/' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Home
                                </Typography>
                            </Box>
                            <Box component={Link} to='terms_and_conditions' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Terms & Conditions
                                </Typography>
                            </Box>
                            <Box component={Link} to='privacy_policy' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Privacy policy
                                </Typography>
                            </Box>
                            <Box component={Link} to='refund_policy' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Refund Policy
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Box>
                            <Typography variant='h4' color='text.white' sx={{ mb: 2 }}>
                                Courses
                            </Typography>
                            <Box component={Link} to='course?name=chandradhaara' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Chandradhaara
                                </Typography>
                            </Box>
                            <Box component={Link} to='course?name=samrudhi' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Samrudhi
                                </Typography>
                            </Box>
                            <Box component={Link} to='course?name=nirvana' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Nirvana
                                </Typography>
                            </Box>
                            <Box component={Link} to='course?name=public_speaking' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Public Speaking
                                </Typography>
                            </Box>
                            <Box component={Link} to='course?name=personal_mentorship' sx={{ textDecoration: 'none' }}>
                                <Typography variant='body1' color='text.white' sx={{ mb: 2 }}>
                                    Personal Mentorship
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box>
                            <Typography variant='h4' sx={{ color: 'text.white', mb: 3 }}>
                                Get in Touch
                            </Typography>
                            <Stack direction='row' spacing={1} sx={{ mb: 2 }}>
                                <LocationOnOutlinedIcon sx={{ color: '#FF4501' }} />
                                <Box>
                                    <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                        Kanjaveli PO
                                    </Typography>
                                    <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                        Kollam
                                    </Typography>
                                    <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                        Kerala - 691602
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack direction='row' spacing={1} sx={{ mb: 2 }}>
                                <EmailOutlinedIcon sx={{ color: '#FF4501' }} />
                                <Box>
                                    <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                        madhubaalan80@gmail.com
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <PhoneInTalkOutlinedIcon sx={{ color: '#FF4501' }} />
                                <Box>
                                    <Typography variant='subtitle1' sx={{ opacity: 0.6 }} color='text.white'>
                                        +91 9747855166
                                    </Typography>

                                </Box>
                            </Stack>

                        </Box>
                    </Grid>
                </Grid>

            </Container>

        </Box>

    )
}

export default Footer;