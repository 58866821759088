import { createTheme } from "@mui/material/styles";
import { purple, red } from '@mui/material/colors';

import Typography from './typography';
import Shadows from "./shadows";

const themeTypography = Typography();

const themeShadows = Shadows();

const theme = createTheme({
    palette: {
        primary: {
            main: '#00A8EA',
            lighter: purple[50],
            contrastText: '#fff'
        },
        secondary: {
            main: '#231F20',
        },
        text: {
            primary: '#008DAF',
            secondary: '#FF5D49',
            white: '#fff',
            yellow: '#FFB200',
            dark: "#231F20",
            gray: "#BABABA"
        },
        white: {
            main: '#fff',
        },
        green: {
            main: '#17cc10',
            lighter: '#e7f9e7',
            contrastText: '#ffffff',

        },
        orange: {
            main: "#fff",
            lighter: "#fee9cc",
            contrastText: "#FFB200",
            dark: "#fff"
        },
        blue: {
            main: '#0f8cff',
            lighter: '#57aeff',
            contrastText: '#fff'
        },
        red: {
            main: '#FF5D49',
            contrastText: '#fff',
        },
        peach: {
            main: '#FFB399'
        },

        blued: {
            main: '#04C6F5'
        }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "white" },
                    style: {
                        padding: '6px 35px',
                        color: "#FF5D49",
                        backgroundColor: "White",
                        '&:hover': {
                            color: 'white',
                            backgroundColor: '#FF5D49',
                        },
                    }
                }
            ],
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    padding: '15px 40px 12px',
                    fontWeight: '800',
                    fontSize: '15px'
                },
                contained: {
                    backgroundColor: '#FF4501',
                    '&:hover': {
                        backgroundColor: '#FF4501',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    background: 'rgba(217, 217, 217, 0.3)'
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#AFAFAF",
                },
            },
        }
    },

    typography: themeTypography
});

export default theme;