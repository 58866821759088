
const Typography = (theme) => ({
    h1: {
        '@media (max-width:600px)': {
            fontSize: '45px',
        },
        fontWeight: 700,
        fontSize: '65px',
        lineHeight: 1.2,
        textTransform: 'capitalize',
        color: '#262d3d',
        fontFamily: '"Raleway", sans-serif'
    },
    h2: {
        fontWeight: 700,
        fontSize: '45px',
        lineHeight: 1.4,
        textTransform: 'capitalize',
        color: '#262d3d',
        fontFamily: '"Raleway", sans-serif'
    },
    h3: {
        fontWeight: 400,
        fontSize: '2.4rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h4: {
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: 1.4,
        textTransform: 'capitalize',
        fontFamily: '"Raleway", sans-serif',
        color: '#262d3d'
    },
    h5: {
        fontWeight: 800,
        fontSize: '1.6rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h6: {
        fontWeight: 600,
        fontSize: '1.4rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    caption: {
        fontFamily: '"raleway", sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        color: '#1b2336'
    },
    body1: {
        fontFamily: '"Nunito Sans", sans-serif',
        fontSize: '16px',
        lineHeight: 1.82,
        fontWeight: 400,
        color: '#415674'
    },
    body2: {
        fontSize: '1rem',
        lineHeight: 1.3
    },
    subtitle1: {
        fontFamily: '"Nunito Sans", sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 1.82
    },
    subtitle2: {
        fontSize: '1.8rem',
        lineHeight: 1.3,
    },
    overline: {
        lineHeight: 1.66
    },
    button: {
        textTransform: 'capitalize'
    }
});

export default Typography;